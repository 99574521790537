
import { defineComponent } from 'vue';
import Modal from '@/components/modals/Modal.vue';
import { Form, Field } from 'vee-validate';
import FormField from '@/components/forms/FormField.vue';
import { StartupGuideSchema } from '@/schemas/startupguide.schemas';
import { StartupGuide } from '@/models/StartupModel';
import { bootstrapIcons } from '@/utils/icons';
import FormIconInput from '@/components/forms/FormIconInput.vue';

const initialValues = {
  code: null,
  title: null,
  subtitle: null,
  icon: null,
  type: null,
} as unknown as StartupGuide;

export default defineComponent({
  name: 'startup-guide-modal',
  components: { Modal, FormField, Form, FormIconInput },
  props: {
    modelValue: { type: Boolean, default: false },
    loading: { type: Boolean, default: false },
    type: { default: 'store' },
    initialValues: { default: null as unknown as StartupGuide },
    appType: { default: null },
  },
  emits: ['update:modelValue', 'onSaved', 'onUpdate'],
  computed: {
    title() {
      return this.type == 'store'
        ? `New ${this.appType} Startup Guide`
        : `Edit ${this.appType} Startup Guide`;
    },
    btnText() {
      return this.type == 'store' ? 'Save' : 'Update';
    },
    loadingText() {
      return this.type == 'store' ? 'Saving...' : 'Updating...';
    },
    dialog: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit('update:modelValue', value);
      },
    },
    selectIcon() {
      return this.formInitialValue.icon;
    },
    formSchema() {
      return StartupGuideSchema;
    },
    formInitialValue() {
      return this.initialValues ?? initialValues;
    },
  },
  data: () => {
    return {
      form: {} as typeof Form,
      formData: {} as unknown as StartupGuide,
      icons: bootstrapIcons,
    };
  },
  watch: {
    dialog(value) {
      if (!value) {
        this.$nextTick(() => {
          this.form.resetForm();
        });
      }
    },
    formInitialValue: {
      handler(value) {
        this.setFieldValue('code', value.code);
        this.setFieldValue('title', value.title);
        this.setFieldValue('subtitle', value.subtitle);
        this.setFieldValue('icon', value.icon);
      },
      deep: true,
    },
  },
  methods: {
    setFieldValue(field, value) {
      if (!this.form.setFieldValue) return;
      this.form.setFieldValue(field, value);
    },
    handleChangeIcon(value) {
      this.setFieldValue('icon', value);
    },
    async handleSubmitForm(values) {
      this.formData = {
        ...values,
        ...{ for_app: this.appType, id: this.initialValues.id },
      };
      const { valid, errors } = await (this.$refs.modalForm as any).validate();

      if (!valid) {
        return false;
      }

      if (this.type == 'store') {
        this.$emit('onSaved', this.formData);
      }

      if (this.type == 'edit') {
        this.$emit('onUpdate', this.formData);
      }
    },
    validateField(field) {
      if (!this.form.validateField) return;
      this.form.validateField(field);
    },
  },
  mounted() {
    this.form = this.$refs.modalForm as typeof Form;
  },
});
