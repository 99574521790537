
import { defineComponent } from 'vue';
import Card from '@/views/new-design/components/Card.vue';
import DataTable from '@/components/datatables/KTDataTable.vue';
import useBreakpoints from 'vue-next-breakpoints';
import Navigator from '@/views/new-design/layout/Navigator.vue';
import { StartupGuide } from '@/models/StartupModel';
import { money } from '@/utils/currency';
import AddGuideModal from '@/views/new-design/pages/Settings/Startup/AddGuideModal.vue';
import toasts from '@/utils/toasts';
import {
  Actions as StarupGuideActions,
  Getters as StarupGuideGetters,
} from '@/store/enums/StartupGuideEnums';
import { mapActions, mapGetters } from 'vuex';

export default defineComponent({
  components: { Card, DataTable, Navigator, AddGuideModal },
  data: () => {
    return {
      tab: 'agent',
      table: {
        total: 0,
        items: [] as Array<StartupGuide>,
      },
      add_startup_card: false,
      loading: false,
      saving: false,
      startupCard: null as unknown as StartupGuide,
      formType: 'store',
      // items: [
      //   {
      //     code: 'upload-logo',
      //     title: 'Upload Logo',
      //     subtitle: 'Upload your business logo',
      //     icon: 'bi bi-file-earmark-arrow-up-fill',
      //     isClosed: false,
      //     type: 'agent'
      //   },
      //   {
      //     code: 'business-color',
      //     title: 'Business Colours',
      //     subtitle: 'Select your business colours',
      //     icon: 'bi bi-palette-fill',
      //     isClosed: false,
      //     type: 'agent'
      //   },
      //   {
      //     code: 'add-user',
      //     title: 'Add Users',
      //     subtitle: 'Add your business users',
      //     icon: 'bi bi-person-fill',
      //     isClosed: false,
      //     type: 'agent'
      //   },
      //   {
      //     code: 'custom-link',
      //     title: 'Custom Link',
      //     subtitle: 'Customize your custom link',
      //     icon: 'bi bi-link-45deg',
      //     isClosed: false,
      //     type: 'agent'
      //   },
      //   {
      //     code: 'review-link',
      //     title: 'Review Link',
      //     subtitle: 'Add your review link',
      //     icon: 'bi bi-chat-left-text-fill',
      //     isClosed: false,
      //     type: 'agent'
      //   },
      //   {
      //     code: 'notification-center',
      //     title: 'Review Notification Center',
      //     subtitle: 'Set your review notification',
      //     icon: 'bi bi-bell-fill',
      //     isClosed: false,
      //     type: 'agent'
      //   },
      //   {
      //     code: 'upload-avatar',
      //     title: 'Avatar',
      //     subtitle: 'Upload your avatar.',
      //     icon: 'bi bi-person-bounding-box',
      //     isClosed: false,
      //     isHidden: false,
      //     type: 'tenant'
      //   },
      //   {
      //     code: 'verify-mobile-number',
      //     title: 'Verify Mobile Number',
      //     subtitle: 'Verify your mobile number.',
      //     icon: 'bi bi-phone',
      //     isClosed: false,
      //     isHidden: false,
      //     type: 'tenant'
      //   },
      //   {
      //     code: 'enter-mobile-number',
      //     title: 'Enter Mobile Number',
      //     subtitle: 'Enter your mobile number.',
      //     icon: 'bi bi-phone',
      //     isClosed: false,
      //     isHidden: false,
      //     type: 'tenant'
      //   },
      //   {
      //     code: 'current-address',
      //     title: 'Current Address',
      //     subtitle: 'Enter your current address.',
      //     icon: 'bi bi-house-fill',
      //     isClosed: false,
      //     isHidden: false,
      //     type: 'tenant'
      //   }
      // ]
    };
  },
  watch: {
    add_startup_card(value) {
      if (!value) {
        this.formType = 'store';
        this.startupCard = null as unknown as StartupGuide;
      }
    },
  },
  computed: {
    ...mapGetters({
      getStartupGuides: StarupGuideGetters.GET_STARTUP_GUIDES,
      getStartupGuide: StarupGuideGetters.GET_STARTUP_GUIDE,
    }),
    breakpoint() {
      return useBreakpoints({
        mobile: 800,
        desktop: [801],
      });
    },
    isMobile() {
      return (this.breakpoint as any)?.mobile?.matches;
    },
    tableHeaders() {
      return [
        { name: 'Icon', key: 'icon' },
        { name: 'Code', key: 'code' },
        { name: 'Title', key: 'title' },
        { name: 'Sub-title', key: 'subtitle' },
        { name: '', key: 'action', hidden: this.isMobile, align: 'end' },
      ];
    },
    tableItems() {
      return this.table.items.filter(
        (item) => item.for_app == this.tab
      ) as unknown as Array<StartupGuide>;
    },
  },
  methods: {
    ...mapActions({
      fetchStartupGuides: StarupGuideActions.FETCH_STARTUP_CARDS,
      updateStartupGuide: StarupGuideActions.UPDATE_STARTUP_CARD,
      deleteStartupGuide: StarupGuideActions.DELETE_STARTUP_CARD,
    }),
    async loadStartupGuides() {
      this.loading = true;
      await this.fetchStartupGuides();

      this.table.items = this.getStartupGuides;
      this.table.total = this.getStartupGuides.length;
      this.loading = false;
    },
    async handleUpdateStartupGuide(payload) {
      this.saving = true;
      await this.updateStartupGuide(payload)
        .then((res) => {
          toasts.success('Startup guide has been updated.');
          this.loadStartupGuides();
          this.add_startup_card = false;
        })
        .catch(() => {
          toasts.error({}, 'Something went wrong. Please try again.');
        })
        .finally(() => {
          this.saving = false;
        });
    },
    editCard(isEdit) {
      this.formType = 'edit';
      this.startupCard = isEdit;
      this.add_startup_card = true;
    },
    confirmDelete(value) {
      toasts.confirm('Are you sure you want to delete this item?', (result) => {
        if (result.isConfirmed) {
          this.handleDeleteStartupGuide(value);
        }
      });
    },
    async handleDeleteStartupGuide(item) {
      await this.deleteStartupGuide({ id: item.id })
        .then(() => {
          toasts.success('Startup guide has been deleted.');
          this.loadStartupGuides();
        })
        .catch(() => {
          toasts.error({}, 'Something went wrong. Please try again.');
        });
    },
  },
  mounted() {
    this.loadStartupGuides();
  },
});
