import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, resolveComponent as _resolveComponent, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "d-flex" }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { class: "ms-2" }
const _hoisted_4 = { key: 1 }
const _hoisted_5 = { class: "d-flex flex-wrap" }
const _hoisted_6 = ["onClick"]
const _hoisted_7 = { class: "fv-plugins-message-container" }
const _hoisted_8 = { class: "fv-help-block" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ErrorMessage = _resolveComponent("ErrorMessage")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.showLabel)
      ? (_openBlock(), _createElementBlock("label", {
          key: 0,
          class: _normalizeClass(["form-label fs-6 fw-bolder text-dark", { required: _ctx.required }])
        }, _toDisplayString(_ctx.label), 3))
      : _createCommentVNode("", true),
    _createElementVNode("div", {
      class: "form-icon-input",
      onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.toggleDropdown && _ctx.toggleDropdown(...args)))
    }, [
      _createElementVNode("div", _hoisted_1, [
        (_ctx.value)
          ? (_openBlock(), _createElementBlock("span", _hoisted_2, [
              _createElementVNode("i", {
                class: _normalizeClass(_ctx.value)
              }, null, 2),
              _createTextVNode(),
              _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.value), 1)
            ]))
          : (_openBlock(), _createElementBlock("span", _hoisted_4, _toDisplayString(_ctx.placeholder), 1))
      ]),
      (_ctx.show)
        ? (_openBlock(), _createElementBlock("div", {
            key: 0,
            ref: "drowpdowncontainer",
            class: "form-icon-input-dropdown",
            onBlur: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.hideDropdown && _ctx.hideDropdown(...args))),
            tabindex: "0"
          }, [
            _createElementVNode("div", _hoisted_5, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.icons, (icon) => {
                return (_openBlock(), _createElementBlock("div", {
                  onClick: ($event: any) => (_ctx.setValue(icon)),
                  key: icon,
                  class: "icon-container me-1 mb-1 mt-1 ms-1"
                }, [
                  _createElementVNode("span", {
                    class: _normalizeClass(icon)
                  }, null, 2)
                ], 8, _hoisted_6))
              }), 128))
            ])
          ], 544))
        : _createCommentVNode("", true)
    ]),
    _createElementVNode("div", _hoisted_7, [
      _createElementVNode("div", _hoisted_8, [
        _createVNode(_component_ErrorMessage, { name: _ctx.name }, null, 8, ["name"])
      ])
    ])
  ], 64))
}